// i18next-extract-mark-ns-start google-pay-for-shopify
//Payment methods
import {AnchorLink} from 'components/AnchorLink';
import {Background} from 'components/Background';
import {ContactSalesButton} from 'components/ContactSalesButton';
import {Content} from 'components/Content';
import {Partners} from 'components/Partners';
import {Section, SectionActions, SectionHeader, SectionImage} from 'components/Section';
import {SEO} from 'components/SEO';
import {SignUpButton} from 'components/SignUpButton';
import {graphql} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';

//Partners
import index2 from 'images/best_payment_gateway_rates.svg';

//Images
import shopify_googlepay from 'images/shopify_google-pay.svg';

// Clients
import index1 from 'images/payment_gateway.svg';
import index3 from 'images/shopify_payment_gateway.svg';

import React from 'react';
import styled from 'styled-components';
import {List} from 'components/List';
import {IndexImage} from 'components/landings/IndexImage';
import {IndexBackground} from 'components/landings/IndexBackground';
import {BlogLink} from 'components/links/Blog';
import {InternalPageLink} from 'components/links/Pages';

const IndexContent = styled.div`
  padding-top: 90px;
  max-width: 500px;
  @media (max-width: 768px) {
    padding-top: 10px;
  }
`;

const SellingPage: React.FC = () => {
  const {t} = useI18next();
  return (
    <>
      <SEO
        path="google-pay-for-shopify"
        title={t('Shopify Google Pay')}
        description={t(
          'Add the Shopify Google Pay integration to your store to let customers pay with one of the most popular digital wallets in the world. Start here ››'
        )}
      />
      <IndexBackground>
        <Content>
          <Section reverseOnMobile>
            <IndexContent>
              <SectionHeader underline tagName="h1">
                <Trans>Get the Shopify Google Pay integration</Trans>
              </SectionHeader>
              <Trans parent="p">
                Hundreds of millions of people use Google Pay, that’s why it’s important to add
                Google Pay to your Shopify store. To do this, you’ll need a payment service provider
                that offers a Shopify Google Pay integration.
              </Trans>
              <Trans parent="p">
                We’re here to help. Our e-commerce payment gateway lets you accept and manage the
                widest range of payment methods from one platform.
              </Trans>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
                <ContactSalesButton />
              </SectionActions>
            </IndexContent>
            <IndexImage src={shopify_googlepay} alt="MONEI google pay" title="MONEI google pay" />
          </Section>
        </Content>
      </IndexBackground>
      <Background>
        <Content>
          <Section>
            <SectionImage
              src={index2}
              alt="MONEI Payment Gateway"
              title="MONEI Payment Gateway"
              width={600}
              height={350}
              mobileWidth={280}
            />
            <div>
              <SectionHeader>
                <Trans>Accept GPay payments and many other Shopify payment methods</Trans>
              </SectionHeader>
              <Trans parent="p">
                Adding Google Pay to your Shopify store is a good start, but it shouldn’t be the
                only payment option you accept. Today, consumers are looking for many payment
                methods at checkout. This includes{' '}
                <InternalPageLink slug="payment-methods/credit-cards">
                  credit and debit cards
                </InternalPageLink>
                , more <BlogLink slug="digital-wallet-ecommerce">digital wallets</BlogLink> like{' '}
                <InternalPageLink slug="payment-methods/paypal">Paypal</InternalPageLink>,{' '}
                <InternalPageLink slug="payment-methods/apple-pay">Apple Pay</InternalPageLink>, and{' '}
                <InternalPageLink slug="payment-methods/click-to-pay">
                  Click to Pay
                </InternalPageLink>
                . Plus <BlogLink slug="local-payment-methods">local payment methods</BlogLink> such
                as <InternalPageLink slug="payment-methods/bizum">Bizum</InternalPageLink> in Spain.
              </Trans>
              <Trans parent="p">
                We aim to help you{' '}
                <InternalPageLink slug="payment-methods">
                  accept more online payment methods
                </InternalPageLink>{' '}
                so you can improve customer satisfaction, reach more people, and sell more. That’s
                why we aggregate all major and{' '}
                <BlogLink slug="alternative-payment-methods">alternative payment methods</BlogLink>{' '}
                into a single merchant dashboard.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Discover MONEI</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
          <Section reverseOnMobile>
            <div>
              <SectionHeader>
                <Trans>Approve more online payments and increase sales</Trans>
              </SectionHeader>
              <Trans parent="p">
                Using <InternalPageLink slug="redsys-alternative">Redsys</InternalPageLink> alone as
                your payment processor can lead to unnecessary{' '}
                <BlogLink slug="online-payment-failure-message">online payment failure</BlogLink>{' '}
                messages and lost sales. That’s why we built a{' '}
                <InternalPageLink slug="features/payments-orchestration">
                  payments orchestration
                </InternalPageLink>{' '}
                feature that lets you create payment processing rules. This way, if your default
                provider is experiencing downtime, we’ll send the transaction to another one.
                Approve more payments, improve the checkout process, and increase your conversion
                rate while giving customers a{' '}
                <BlogLink slug="frictionless-payments">frictionless payment</BlogLink> experience.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Get MONEI Now</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
            <SectionImage
              src={index1}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={500}
              height={500}
              mobileWidth={280}
            />
          </Section>
          <Section>
            <SectionImage
              src={index3}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={400}
              height={428}
              mobileWidth={180}
            />
            <div>
              <SectionHeader>
                <Trans>
                  Enjoy the lowest{' '}
                  <InternalPageLink slug="shopify-payment-gateway">
                    Shopify payment gateway
                  </InternalPageLink>{' '}
                  transaction fees
                </Trans>
              </SectionHeader>
              <Trans parent="p">
                Your success is our success. That’s why we’re the only payment gateway to offer
                dynamic pricing — as you sell more, you’ll pay less on transaction fees. You’ll make
                more money per order that you can reinvest to grow your store.
              </Trans>
              <Trans parent="p">
                Choose a flexible Shopify payment gateway that scales with your business —{' '}
                <InternalPageLink slug="pricing">view pricing here</InternalPageLink>.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
        </Content>
      </Background>
      <Content fullWidth>
        <Section style={{maxWidth: 900, margin: 'auto'}}>
          <div>
            <SectionHeader underline tagName="h3">
              <Trans>How to add the Shopify Google Pay integration to your website</Trans>
            </SectionHeader>
            <Trans parent="p">
              The process is quick and easy. In a few short steps, you can connect your Shopify
              store with MONEI and start accepting Google Pay payments (and more payment methods)
              today.
            </Trans>
            <List>
              <Trans parent="li">
                <AnchorLink href="https://dashboard.monei.com/?action=signUp">
                  Sign up for MONEI here
                </AnchorLink>
              </Trans>
              <Trans parent="li">
                <AnchorLink href="https://support.monei.com/hc/es-es/articles/360018290877-How-do-I-activate-Google-Pay-">
                  Configure Google Pay
                </AnchorLink>{' '}
                payments in your MONEI dashboard
              </Trans>
              <Trans parent="li">
                Follow the{' '}
                <AnchorLink href="https://docs.monei.com/docs/e-commerce/shopify/">
                  Connect MONEI
                </AnchorLink>{' '}
                to your Shopify store
              </Trans>
            </List>
            <Partners />
          </div>
        </Section>
      </Content>
    </>
  );
};

export default SellingPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {ns: {in: ["common", "google-pay-for-shopify"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
